import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { getTestValueAB, TESTS_DICTIONARY } from '../../../../../utilities/ABTesting';
import { getBrowserId } from '../../../../../utilities/browserUtils';
import { DATE_FORMATS, PACKAGE_CHARGE_TYPE, ROOM_RATE_TYPE } from '../../../../../utilities/constants';
import { getNumberOfBillableGuests } from '../../../../../utilities/guestsUtils';
import { getInteractionsUserId } from '../../../../../utilities/interactionsUtils';
import { GwDatesWrapper } from '../../../../_internal_date_/gwDatesWrapper';
import { MODAL_KEYS } from '../../../../GlobalModal/modalSource';
import Loyalty from '../../../../Loyalty/Loyalty';
import { LOYALTY_TYPES } from '../../../../Loyalty/utilities/constants';
import { getTimeRemaining } from '../../../../TimerModalContent/utilities/timerFunctions';
import { Box } from '../../../../ui-kit';
import theme from '../../../../ui-kit/themes/theme';
import {
    editCopyByReplaceMultipleStrings,
    editCopyByReplaceString,
    pluralize
} from '../../../../utilities/copyFunctions';
import { handleTotal, handleTotalWithTaxes } from '../../../../utilities/utils';
import { SummaryAccordion } from '../../Accordion';
import * as COPY from '../utilities/copy';
import CartOverlay from './CartOverlay';
import { SuitePlanContent } from './Content';
import CartLogo from './Content/CartLogo';
import EmptyCartContent from './Content/EmptyCartContent';
import { Header } from './Header';

const propTypes = {
  /** Flag to indicate if the cost summary should start open */
  shouldStartOpened: PropTypes.bool,

  /** Flag to indicat if the cost summary is on the payment page */
  onPaymentPage: PropTypes.bool
};

export const Summary = ({ shouldStartOpened, onPaymentPage, openModal, showLoyaltyDisclaimerOnly, ...restProps }) => {
  // Overlay State
  const [overlayVisible, setOverlayVisible] = useState(false);
  // Handle Open Cart
  const handleOpenCart = () => {
    setOverlayVisible(prev => !prev);
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.md})`
  });

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.xl });

  const optimizelySummaryAccordionDesktop = getTestValueAB(TESTS_DICTIONARY.optimizelySummaryAccordionDesktop, false);

  const history = useHistory();

  const {
    summaryTotal,
    userOffers,
    activeOffers,
    showLoyalty,
    loyaltyOptIn,
    pointsBalance,
    suiteTotal,
    currentSelectedPackages,
    isUserAuthenticated,
    taxesAndFees,
    roomAdjustmentTax,
    selectedSuite,
    resortLocationUrlParam,
    isCartVariation,
    onCmpPage,
    resortLocation,
    cartResortLocation,
    clearSelectedSuite,
    clearSelectedPackages,
    resetCartResortLocation,
    setExpiredCart,
    isCartExpired,
    adultsCount,
    arrival,
    departure,
    offerCode,
    kidsAges,
    updateRateCodes,
    numberOfGuests,
    loyaltyEarnThresholdNights
  } = restProps;

  const handleSummaryHeaderTotalAmount = () => {
    return Number(
      isUserAuthenticated
        ? !onPaymentPage
          ? handleTotal(
              userOffers,
              activeOffers,
              showLoyalty,
              loyaltyOptIn,
              pointsBalance,
              suiteTotal,
              currentSelectedPackages
            ).toFixed(2)
          : handleTotalWithTaxes(
              userOffers,
              activeOffers,
              showLoyalty,
              loyaltyOptIn,
              pointsBalance,
              suiteTotal,
              taxesAndFees,
              roomAdjustmentTax,
              currentSelectedPackages
            )
        : summaryTotal.toFixed(2)
    );
  };

  // Cart Has at least suite
  const cartHasSuite = selectedSuite && Object.keys(selectedSuite).length > 0;
  // Count of packages in the cart
  let packagesCount = 0;
  if (isCartVariation && cartHasSuite) {
    const totalPackages = currentSelectedPackages
      .filter(packageItem => !packageItem.isAdjustment)
      .reduce((acc, packageItem) => {
        if (packageItem.chargeType === PACKAGE_CHARGE_TYPE.personPerDay) {
          return acc + 1;
        }
        if (packageItem.quantity) {
          return acc + packageItem.quantity;
        }
        return acc;
      }, 1);

    packagesCount = totalPackages;
  }

  // Block body scroll if overlay is visible and mobile
  if (isCartVariation && isMobile) {
    if (overlayVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  // Check if there is a PUSH action on history and close the cart
  if (isCartVariation) {
    history.listen(action => {
      if (action === 'PUSH') {
        setOverlayVisible(false);
      }
    });
  }

  // Open Modal for modify cart item
  const openModifyCartItemModal = (item, type, variation, confirmationFunction) => {
    openModal(
      {
        keyContentComponent: MODAL_KEYS.CART_MODIFY_MODAL,
        params: {
          item,
          type,
          variation,
          confirmationFunction
        }
      },
      false
    );
  };

  // Clear selected suite and packages if cart location is different from resort location
  useEffect(() => {
    if (!isCartVariation || !cartResortLocation) return;
    if (cartResortLocation !== resortLocation) {
      clearSelectedSuite();
      clearSelectedPackages();
      resetCartResortLocation();
    }
  }, [
    cartResortLocation,
    clearSelectedPackages,
    clearSelectedSuite,
    isCartVariation,
    resetCartResortLocation,
    resortLocation
  ]);

  // expiration timer
  useEffect(() => {
    // Dont run if not on cart version
    if (!isCartVariation) return;
    // Dont run if no suite selected
    if (!selectedSuite || isCartExpired) return;
    const expirationTime = localStorage.getItem('timerObj');
    // Dont run if no expiration time, this is set byt the timer on Plan page
    if (!expirationTime) return;
    const countdown = setInterval(() => {
      const timer = getTimeRemaining(expirationTime);

      if (timer.total < 0) {
        setExpiredCart();
        clearSelectedSuite();
        clearSelectedPackages();
        clearInterval(countdown);

        return;
      }
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [clearSelectedPackages, clearSelectedSuite, isCartExpired, isCartVariation, selectedSuite, setExpiredCart]);

  const updateSuite = useCallback(
    async e => {
      e.target.classList.add('disabled');
      const roomWithPassRate = selectedSuite?.rateOptions?.find(
        rate => rate.rateType === ROOM_RATE_TYPE.roomWithWaterParkPass
      );
      const requestObject = {
        NumberOfAdults: adultsCount,
        Arrival: GwDatesWrapper.format(arrival, DATE_FORMATS.default),
        Departure: GwDatesWrapper.format(departure, DATE_FORMATS.default),
        OfferCode: offerCode,
        Location: resortLocation,
        RateCode: roomWithPassRate.rateCode,
        kidsAges: kidsAges.join(','),
        uuid: getBrowserId(),
        intUserId: getInteractionsUserId(),
        suiteType: selectedSuite.suiteCategory,
        isDateChanged: false,
        skipCompareValidation: true,
        availabilityKey: roomWithPassRate.availabilityKey
      };
      const newSelectedSuite = {
        ...selectedSuite,
        ...roomWithPassRate
      };

      await updateRateCodes(requestObject, newSelectedSuite);
      e?.target?.classList?.remove('disabled');
    },
    [adultsCount, arrival, departure, kidsAges, offerCode, resortLocation, selectedSuite, updateRateCodes]
  );

  useEffect(() => {
    const waterPassNoticeContainer = document.querySelector('#water-pass-notice');
    if (!waterPassNoticeContainer) return;

    if (
      selectedSuite?.rateType === ROOM_RATE_TYPE.roomOnlyWithoutWaterParkPass &&
      selectedSuite?.rateOptions?.length > 1
    ) {
      waterPassNoticeContainer.style.display = 'block';
      const roomWithPassRate = selectedSuite?.rateOptions?.find(
        rate => rate.rateType === ROOM_RATE_TYPE.roomWithWaterParkPass
      );
      const roomOnlyRate = selectedSuite?.rateOptions?.find(
        rate => rate.rateType === ROOM_RATE_TYPE.roomOnlyWithoutWaterParkPass
      );
      const price = roomWithPassRate?.averageNightlyBaseRate - roomOnlyRate?.averageNightlyBaseRate ?? 0;

      const numberOfBillableGuests = getNumberOfBillableGuests(adultsCount, kidsAges);
      const copyObject = {
        '<PASSES>': numberOfBillableGuests,
        '<PRICE>': Math.round(price),
        '<PASSES_TEXT>': pluralize('pass', numberOfBillableGuests)
      };
      const waterPassNoticeText = document.querySelector('#water-pass-notice-text');
      waterPassNoticeText.innerHTML = editCopyByReplaceMultipleStrings(COPY.ROOM_WITH_NO_WATER_PASS, copyObject);
      const waterPassNoticeActionText = document.querySelector('#water-pass-notice-action-text > span');
      waterPassNoticeActionText.innerHTML = editCopyByReplaceString(
        COPY.ADD_PASSES,
        pluralize('pass', numberOfGuests),
        '<PASSES_TEXT>'
      );
      waterPassNoticeActionText.addEventListener('click', updateSuite, false);
    } else {
      // remove the notice
      waterPassNoticeContainer.style.display = 'none';
    }
  }, [numberOfGuests, selectedSuite, updateSuite, adultsCount, kidsAges]);

  if (showLoyaltyDisclaimerOnly) {
    return (
      <Box mx={3}>
        <Loyalty
          loyaltyType={LOYALTY_TYPES.earn}
          numberOfNights={restProps.numberOfNights}
          isUserAuthenticated={isUserAuthenticated}
          suitNightRate={restProps.suiteTotal}
          pointsMultiplier={restProps.getPointsMultiplier}
          loyaltyEarnThresholdNights={loyaltyEarnThresholdNights}
          {...restProps}
        />
      </Box>
    );
  }

  return (
    <SummaryAccordion
      style={!isCartVariation ? { border: '1px solid #CCF3F4', borderRadius: '24px' } : null}
      shouldStartOpened={optimizelySummaryAccordionDesktop && !isMobile && onPaymentPage ? false : shouldStartOpened}>
      {!isCartVariation ? (
        <SummaryAccordion.Header>
          <Header onPaymentPage={onPaymentPage} total={handleSummaryHeaderTotalAmount()} />
        </SummaryAccordion.Header>
      ) : null}
      {/* Cart logo */}
      {isCartVariation && !onPaymentPage && !onCmpPage ? (
        <CartLogo
          toggleOpen={handleOpenCart}
          packagesCount={packagesCount}
          isMobile={isMobile}
          isTablet={isTablet}
          summaryTotal={summaryTotal}
          overlayVisible={overlayVisible}
        />
      ) : null}
      {/* Suite Plan Content */}
      <CartOverlay
        {...restProps}
        cartHasPackages={cartHasSuite}
        overlayVisible={overlayVisible}
        toggleOpen={handleOpenCart}>
        {/* Empty Cart */}
        {isCartVariation && (!cartHasSuite || isCartExpired) ? (
          <EmptyCartContent resortLocationUrlParam={resortLocationUrlParam} isCartExpired={isCartExpired} />
        ) : (
          <SummaryAccordion.Content isCartVariation={isCartVariation}>
            {({ expanded }) => {
              return (
                <SuitePlanContent
                  expanded={expanded}
                  onPaymentPage={onPaymentPage}
                  openModifyCartItemModal={openModifyCartItemModal}
                  toggleOpen={handleOpenCart}
                  updateSuite={updateSuite}
                  clearSelectedSuite={clearSelectedSuite}
                  {...restProps}
                />
              );
            }}
          </SummaryAccordion.Content>
        )}
      </CartOverlay>
    </SummaryAccordion>
  );
};

Summary.propTypes = propTypes;

Summary.defaultProps = {
  shouldStartOpened: true
};
